.text{
    font-size:medium;
}
.width{
    margin-right:65px;
}
.Heading{
    font-size: 60px;
    font-weight: 700;
    color: #2b4b80;
    padding-top: 15%;
    font-weight: 750;
    line-height: 1.2;
    font-family: "Poppins", sans-serif;
}
.content{
    padding-top: 20px;
    font-family: "Poppins", sans-serif;
    color: #707b8e;
    font-size: 16px;
    line-height: 30px;
    font-weight: normal;
}
.we-create-cap{
    color: #2b4b80;
    font-size: 50px;
    font-weight: 700;
    line-height: 1.3;
    margin-bottom: 29px;
    padding-right: 25px;
}
.boxy:hover{
    opacity:0.5;
    transition-duration:0.5s;
}
.bottom-left {
    position: absolute;
    bottom: 0px;
    left: 0px;
    padding:29px 50px 10px 50px;
    border-radius: 10px;
    background-color: white;
  }
  

.business{
    border-radius: "32px";
}
@media only screen and (max-width: 600px) {
    .card {
      float: none;
      margin: 0 auto;
    }
    .Team{
        float: none;
      margin: 0 auto;
    }
  }
  @media (max-width:629px) {
  img#optionalstuff {
    display: none;
  }
}
.jumbotron{
  height: 300px;
  width:auto;
  background-image: url("./gradient.jpeg");
  background-size: cover;
  color:white;

}
*{
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -msbox-sizing: border-box;
}
body{
 overflow-x: hidden;
}
img{
 max-width:100%;
}