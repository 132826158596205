@import url('https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap');

*{
  padding:0;
  margin:0;
}
ul{
    padding:0;
    list-style:none;
}
.class-title{
    font-family: 'Dela Gothic One', cursive;
    color:#e1ad01;
}



.banner-image{
    text-align: center;
    display : block;
}

.card-section{
    text-align: center;
    align-items: center;
    justify-content: center;
    display : block;
}


.scroll::-webkit-scrollbar {
    width: 1.5px;
  }
  
 
.scroll::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
 
.scroll::-webkit-scrollbar-thumb {
    background: #888;
  }
  

.scroll::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  @media only screen and (max-width: 768px) {
       .card-section{
         margin-left: 50px;
       }
    }
  