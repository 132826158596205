.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	color:#FFF;
	text-align:center;
  font-size:30px;
  z-index:100;
}

.my-float{
	margin-top:16px;
}